<footer class="footer-area">
    <div class="container">
        <h3>ExamGym.com</h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>©ExamGym 2023 All rights reserved.</p>
    </div>
</footer>

<!--app-demo-sidebar></app-demo-sidebar-->
