import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { TestsService } from 'src/app/services/tests.service';

@Component({
  selector: 'app-exam-wizard',
  templateUrl: './exam-wizard.component.html',
  styleUrls: ['./exam-wizard.component.scss']
})
export class ExamWizardComponent implements AfterViewInit {

  minCounter = 110;
  secCounter = 15;
  question = {};
  answers = [];
  rightPanelClass = "col-md-6 tab-100 right-panel-flex";
  currentIndex = 0;
  total = 0;
  leftSidePresent = true;
  selectedOption;

  exam;
  examBlocks = [];
  blockQuestions = [];

  constructor(private service: TestsService){}

  ngAfterViewInit() {
    setInterval(() => {
      this.tickTock();
    }, 1000);
  }

  tickTock() {
    if(this.minCounter == 0 && this.secCounter == 0) {
      console.log("finita");
    } else {
      if(this.secCounter == 0) {
        this.secCounter = 59;
        this.minCounter--;
      } else {
        this.secCounter--;
      }
    }

    document.getElementById("minutes").innerText = '' + this.minCounter;
    document.getElementById("seconds").innerText = ((this.secCounter < 10 ? '0' : '') + this.secCounter);
  }

  ngOnInit() {
      console.log("service questions:", this.service.questions);
      this.total = this.service.questions.length;
      this.blockQuestions = this.service.questions;
      this.question = this.service.questions[0];
      this.answers = new Array<string>(this.total);
      console.log(" this.question['description']",  this.question['description'])
      this.leftSidePresent =  this.question['description'] && this.question['description'].length > 0;
      console.log("this.leftSidePresent", this.leftSidePresent);
  }

  prev() {
    if(this.currentIndex > 0) {
      this.currentIndex--;
      this.question = this.blockQuestions[this.currentIndex];
    }
  }

  next() {
    if(this.currentIndex < this.total - 1) {
      this.currentIndex++;
      this.question = this.blockQuestions[this.currentIndex];
    }
  }

  optionChecked(option) {
    this.answers[this.currentIndex] = option.content;
  }

  submit() {
    console.log("this.answers", this.answers);
  }

}
