<div class="main-banner item-bg-three ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text text-start">
                    <div *ngIf="isLargeScreen">
                        <h1>Get Your</h1>
                        <h1><span>1600</span> in SAT</h1>
                        <p style="margin-top: 30px;">Elevate your SAT performance with ExamGym</p>
                        <p style="margin-bottom: 30px;">through hands-on practice tests!</p>
                        <span (click)="onClick('contact')" class="app-button">Contact Us</span>
                        <span (click)="toSignUp()" class="app-button">Get Your Test</span>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>

<div *ngIf="!isLargeScreen">
    <div style="position: absolute; top: 130px; width: 100%; text-align: center; z-index: 1;">
        <h3 style="font-size: 35px; font-weight: 800; margin-bottom: 10px; color: #1C3169;">Get Your </h3>
        <h1 style="font-size: 50px; font-weight: 800; color: #1C3169;"> <span style="font-size:50px; color:#F52E3F; font-weight: 800;">1600</span> in SAT</h1>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 25px; align-items: center; flex-direction: column;">
        <h5 style="margin-bottom: 5px; font-weight: 600; color:#1C3169;">Elevate your SAT performance</h5>
        <h5 style="font-weight: 600; color: #1C3169;">through hands-on practice tests!</h5>
    </div>
   
    <div style="display: flex; justify-content: center; margin-top: 20px; align-items: center;">
        <!--p style="margin-top: 30px;">Elevate your SAT performance with ExamGym</p>
        <p style="margin-bottom: 30px;">through hands-on practice tests!</p>
        <a (click)="onClick('contact')" style="border: 2px solid; padding: 14px 35px;font-size: 16.5px;" class="btn btn-primary">Contact Us</a>
        <a (click)="toSignUp()"  style="border: 2px solid; padding: 14px 30px;font-size: 16.5px;" class="btn btn-primary">Get Your Test</a-->
        <span (click)="onClick('contact')" class="app-button">Contact Us</span>
        <span (click)="toSignUp()"  class="app-button">Get Your Test</span>
    </div>
   
</div>

<app-services></app-services>

<app-cta></app-cta>

<app-feedback></app-feedback>


<app-contact></app-contact>

<!--app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>


<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>
<app-footer></app-footer-->    
