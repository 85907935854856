import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent {

  satExamId: number = 50;

  
  constructor(private router: Router) {}

  ngOnInit() {
    console.log("exam on init")
  }

  toExam(examId) {
    console.log("examId", examId)
   // this.router.navigate(["/",examId]);
    //this.router.navigate(["/","auth", "register", examId]);
    this.router.navigate(["/","exams", "start"], 
    {
      queryParams: {
        id: examId
      }
    }
     );
  }
}

