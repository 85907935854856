<div id="exams" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How It Works</h2>
            <p>Ready to make your scores soar?</p>
            <p>Start practicing with us today!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <!--div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div-->
                    </div>
                    <div class="services-content">
                        <span style="border: 2px solid;" class="app-button" (click)="toExam({satExamId})">Start</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <span style="border: 2px solid;" class="app-button" (click)="toExam(90)">Start</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg" alt="services-img">
                        <!--div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div-->
                    </div>
                    <div class="services-content">
                       
                        <span  style="border: 2px solid;" class="app-button" (click)="toExam(10001)" disabled >15 Sep at 20:00 CEST</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>