import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller,
              private router: Router) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  toSignUp() {
    this.router.navigate(["/","auth", "register"]);
  }

}
