<div class="main overflow-hidden">
    <div class="countdown" style="display: flex; justify-content: center; align-items: center;">
        <h3 style="margin-bottom: 8px;margin-right: 10px;" id="minutes"></h3>
        <span style="margin-bottom: 8px;">:</span>
        <h3 style="margin-bottom: 8px;margin-left: 10px;" id="seconds"></h3>
    </div>
    <div class="row">
        <div *ngIf="leftSidePresent" class="col-md-6 tab-100 order-tab tab-none">
            <div class="side" style="display: block; margin-top: 150px; margin-left: 30px; margin-right: 30px;">
              
                <div *ngFor="let description of question['description']" class="container">
                    <div *ngIf="description['type'] == 'image'" style="margin-left: 30px; margin-right: 50px;">
                        <img style="display: block; margin-left: auto; margin-right: auto;" [src]="description.content">
                    </div>
                    <div *ngIf="description.type == 'text'" class="container" style="margin-right: 50px;">
                        <p style="font-size: 16px; font-weight: 500; color: #1C3169; margin: 30px">
                            {{ description.content }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!--div class="splitter-line"> &nbsp; </div-->
       
        <div class="col-md-6 tab-100 right-panel-flex">
            <div class="container" style="margin-left: 30px;">
                <div class="container" style="margin-top: 110px; margin-bottom: 30px; margin-right: 50px;">
                    <div class="py-2 h5" style="margin-right: 50px;">
                        <div *ngFor="let quest of question['question']" class="container" style="margin-top: 30px;">
                            <p style="color: #1C3169; font-size: 16px; font-weight: 500;">
                                {{quest.content}}
                              </p>
                        </div>  
                   </div>
                </div>
                <div class="question ml-sm-5 pl-sm-5 pt-2">           
                    <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="options">
                        <div *ngFor="let option of question['options']" class="container">
                            <label *ngIf="option.type == 'text' " class="options" style="display: flex; margin: 20px " (click)="optionChecked(option)">
                                {{option.content}}
                                <input type="radio" name="radio">
                                <span class="checkmark"></span>
                            </label>
                            <label *ngIf="option.type == 'image' " class="options" style="display: flex; margin: 20px;" (click)="optionChecked(option)">
                                <input type="radio" name="radio">
                                <span class="checkmark"></span>
                                <img style="display: block; margin-left: auto; margin-right: auto;" [src]="option.content">
                            </label>
                        </div>     
                    </div>
                </div>
            </div>
            <div class="container" style="margin-bottom: 30px;">
                <div class="next-prev">
                    <span  *ngIf="currentIndex > 0" class="wizzard-button" id="step2btn" (click)="prev()"><i class="fa-solid fa-arrow-left" style="margin-right: 20px;"></i>Prev Question</span>
                    <span *ngIf="currentIndex < total - 1" class="wizzard-button" type="button" id="step1btn" (click)="next()">Next Question<i class="fa-solid fa-arrow-right" style="margin-left: 20px;"></i></span>
                    <span *ngIf="currentIndex > total - 2" class="submit-button-round" style="width: 140px;" type="button" id="step1btn" (click)="submit()">Submit</span>
                </div>             
            </div>
        </div>
    </div>
</div>