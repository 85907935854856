import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {

  isLargeScreen = true;

  constructor(private viewportScroller: ViewportScroller,  private breakpointObserver: BreakpointObserver,
    private router: Router) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.isLargeScreen = false; 
      }
      if (state.breakpoints[Breakpoints.Small]) {
        this.isLargeScreen = false; 
      }
      if (state.breakpoints[Breakpoints.Medium]) {
        this.isLargeScreen = false; 
      }
      if (state.breakpoints[Breakpoints.Large]) {
  
        this.isLargeScreen = true; 
      }
      if (state.breakpoints[Breakpoints.XLarge]) {
  
        this.isLargeScreen = true; 
      }
    });
  }

  toSignUp() {
    this.router.navigate(["/","auth", "register"]);
  }

}
