import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TestsService {


  constructor( private http: HttpClient) { }

  registerExam(examData) {
    return this.http
      .post <any>(environment.PROXY_URL + 'builder', examData, {})
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  questions = [
    { 
      description : [
        {
          type: "text",
          content: "Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ? Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "In the following table you can see her metrics",
          order: 2
        },
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/content1.jpg",
          order: 3
        }
      ],
      question: [
        {
          type: "text",
          content: "Q. Which option best describes your job role ? Why do you think it better than others ? When you'll have your answers ? Are you sure ?" +
          "Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "Keep in mind that there are some custom details there.",
          order: 2
        },
      ],
      options: [
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/option1.png",
          order: 3
        },
        {
          type: "text",
          content: "x < 7 and y > 8",
          order: 1
        },
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/option2.png",
          order: 3
        },
        {
          type: "text",
          content: "Barbie's x + 6x = 25",
          order: 2
        },
        {
          type: "text",
          content: "3 Barbie's Petrenta",
          order: 3
        },
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/content1.jpg",
          order: 3
        }
      ]
    },



    { 
      description : [
        {
          type: "text",
          content: "Second, Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ? Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "Second, In the following table you can see her metrics",
          order: 2
        },
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/content1.jpg",
          order: 3
        }
      ],
      question: [
        {
          type: "text",
          content: "Second, Q. Which option best describes your job role ? Why do you think it better than others ? When you'll have your answers ? Are you sure ?" +
          "Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "Second, Keep in mind that there are some custom details there.",
          order: 2
        },
      ],
      options: [
        
        {
          type: "text",
          content: "x < 7 and y > 8",
          order: 1
        },
        {
          type: "text",
          content: "x < 70 and y > 80",
          order: 1
        },
        
        {
          type: "text",
          content: "Barbie's x + 6x = 25",
          order: 2
        },
        {
          type: "text",
          content: "3 Barbie's Petrenta",
          order: 3
        }
      ]
    },




    { 
      description : [
        {
          type: "text",
          content: "Third, Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ? Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "Third, In the following table you can see her metrics",
          order: 2
        },
        {
          type: "image",
          content: "https://d2if7zbaddaglu.cloudfront.net/static/content1.jpg",
          order: 3
        }
      ],
      question: [
        {
          type: "text",
          content: "Third, Q. Which option best describes your job role ? Why do you think it better than others ? When you'll have your answers ? Are you sure ?" +
          "Q. Which option best describes your job role? Why do you think it better than others ? When you'll have your answers ? Are you sure ?",
          order: 1
        },
        {
          type: "text",
          content: "Third, Keep in mind that there are some custom details there.",
          order: 2
        },
      ],
      options: [
        
        {
          type: "text",
          content: "x < 7 and y > 8",
          order: 1
        },
        {
          type: "text",
          content: "x < 70 and y > 80",
          order: 1
        },
        
        {
          type: "text",
          content: "Barbie's x + 6x = 25",
          order: 2
        },
        {
          type: "text",
          content: "3 Barbie's Petrenta",
          order: 3
        }
      ]
    }
  ]


  getExam(examId) {
    return this.http
    .get <any>(environment.PROXY_URL + 'exam/' + examId)
    .pipe(catchError(err => {
      return throwError(err);
    }));
  }

  getBlockQuestions(blockId) {
    return this.http
    .get <any>(environment.PROXY_URL + 'exam/block/' + blockId)
    .pipe(catchError(err => {
      return throwError(err);
    }));
  }

}
